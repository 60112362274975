import { ref } from "@vue/composition-api";
import store from "@/store/index";
import router from "@/router/index";

const useAuth = () => {
  const email = ref("");
  const password = ref("");
  const codigo = ref("");
  const loading = ref(false);
  const formLogin = ref(null);
  const idParqueEvento = ref(process.env.VUE_APP_ID_PARQUE_EVENTOS);

  const validateForm = () => {
    return formLogin.value.validate();
  };

  const signIn = async () => {
    if (!validateForm()) return;
    loading.value = true;
    const payload = {
        usuario: email.value,
        clave: password.value,
        idParque: idParqueEvento.value,
        idOrigen: -5
    }
    const user = await store.dispatch("signInValidate", payload);
    loading.value = false;

    if (!user) return;

    await store.dispatch("saveDataProfile", user);

    if (store.getters.esTesorero) {
        router.push("/mantenimiento");
    } else {
        router.push("/");
    }
  };

  const signInBoletero = async () => {
    if (!validateForm()) return;
    loading.value = true;
    const payload = {
        usuario: email.value,
        clave: password.value,
        codigo: codigo.value,
        idParque: idParqueEvento.value,
        idOrigen: -6
    }
    const response = await store.dispatch("signInValidate", payload);
    loading.value = false;

    if (!response) return;

    loading.value = true;
    const usuarioAsignacion = await getProfileBoletero(response);
    loading.value = false;

    if(!usuarioAsignacion) return store.dispatch("logout");

    await store.dispatch("saveDataProfile", usuarioAsignacion);

    if (store.getters.esTesorero) {
        router.push("/mantenimiento");
    } else {
        router.push("/");
    }
  };

  const getProfileBoletero = async (user) => {
    const dataAsignacion = await store.dispatch("getProfileBoletero", {
      user: user, 
      idParque: idParqueEvento.value, 
      idUsuario: user?.IDUSUARIO, 
      codigo: codigo.value
    })
    if(!dataAsignacion) return null;
    return dataAsignacion;
  }

  const logout = async () => {
    await store.dispatch("logout");
    router.push({ name: "auth-login" }).catch((err) => {});
  };

  return {
    email,
    password,
    codigo,
    loading,
    formLogin,
    //
    signIn,
    signInBoletero,
    logout,
  };
};

export default useAuth;
